<template>
  <div style="padding-bottom: 50px">
    <el-row>
      <el-col :span="2">
        <TableTitleComponent title="IQC时效"/>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="query" size="mini" :loading="loading">刷新数据</el-button>
      </el-col>
    </el-row>
    <el-table :data="iqcList">
      <el-table-column align="center" prop="stationName" label="工站\时效"></el-table-column>
      <el-table-column v-for="item in iqcCols" :key="item.prop" align="center" :prop="item.prop" :label="item.label">
        <template slot-scope="scope">
          <el-link v-if="scope.row[item.prop]&&scope.row.stationName!='合计'" type="primary" @click="showItemDialog('10',scope.row.stationId,item.prop)">{{ scope.row[item.prop] }}</el-link>
          <template v-else>{{ scope.row[item.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>

    <TableTitleComponent title="RMC时效" style="margin-top: 100px"/>
    <el-table :data="rmcList">
      <el-table-column align="center" prop="stationName" label="工站\时效"></el-table-column>
      <el-table-column v-for="item in rmcCols" :key="item.prop" align="center" :prop="item.prop" :label="item.label">
        <template slot-scope="scope">
          <el-link v-if="scope.row[item.prop]&&scope.row.stationName!='合计'" type="primary" @click="showItemDialog('20',scope.row.stationId,item.prop)">{{ scope.row[item.prop] }}</el-link>
          <template v-else>{{ scope.row[item.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="在库时效明细" :visible.sync="dialogVisible" :close-on-click-modal="false" width="95%">
      <el-table :data="itemList" border stripe v-loading="loading" height="500">
        <el-table-column width="200" label="借出单号" prop="lendOrderCode"></el-table-column>
        <el-table-column width="120" label="备件编码" prop="materialCode"></el-table-column>
        <el-table-column width="200" label="资产码" prop="assetSn"></el-table-column>
        <el-table-column width="150" label="入库时间" prop="agingStartTime"></el-table-column>
        <el-table-column width="120" label="小时" prop="hourCnt"></el-table-column>
        <el-table-column width="150" label="工站" prop="currentStationName"></el-table-column>
        <el-table-column width="200" label="订单类型" prop="orderTypeName"></el-table-column>
        <el-table-column width="150" label="计划大类" prop="planCategoryName"></el-table-column>
        <el-table-column width="150" label="备件来源" prop="materialSourceName"></el-table-column>
        <el-table-column width="120" label="运作产线" prop="chargeLineName"></el-table-column>
        <el-table-column width="120" label="供应商代码" prop="supplierCode"></el-table-column>
        <el-table-column width="300" label="供应商名称" prop="supplierName"></el-table-column>
        <el-table-column width="180" label="检验收货ASN" prop="asnNo"></el-table-column>
        <el-table-column width="300" label="暂存收货ASN" prop="asnDoc"></el-table-column>

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import TableTitleComponent from "@/components/TableTitleComponent.vue";

export default {
  name: "InStoreAgingView",
  components: {TableTitleComponent},
  data() {
    return {
      iqcCols: [{prop: 'h24', label: '24H'}, {prop: 'h48', label: '48H'}, {prop: 'h72', label: '72H'}, {prop: 'more', label: '72H以上'}],
      rmcCols: [{prop: 'h24', label: '24H'}, {prop: 'h36', label: '36H'}, {prop: 'h48', label: '48H'}, {prop: 'more', label: '48H以上'}],
      iqcList: [],
      rmcList: [],
      itemList: [],
      loading: false,
      dialogVisible: false,
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      this.loading = true
      this.$axios.get('report/queryInStoreAgingHourCnt').then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        let obj = res.data || {}
        this.iqcList = obj.iqcList
        this.rmcList = obj.rmcList
      }, error => {
        this.loading = false;
        return this.$message.error('刷新失败，' + error.message);
      })
    },
    showItemDialog(testType, stationId, hourType) {
      this.itemList = []
      this.dialogVisible = true
      this.loading = true
      this.$axios.post('inStoreAgingView/queryInStoreAgingItem', {
        testType, stationId, hourType
      }).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.itemList = res.data || []
      }, error => {
        this.loading = false;
        return this.$message.error('加载明细数据失败，' + error.message);
      })
    }
  }
}
</script>

<style scoped>

</style>